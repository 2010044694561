<template>
  <div id="player"></div>
</template>

<script>
import flowplayer from '@flowplayer/player';
import { FLOWPLAYER_STYLE_URL } from './settings';

export default {
  name: 'OapFlowplayer',

  props: {
    token: { type: String, default: '' },
    src: { type: String, default: '' },
    poster: { type: String, default: '' },
    ratio: { type: String, default: '' },
    autoplay: { type: Boolean, default: false },
    muted: { type: Boolean, default: true },
  },

  mounted() {
    this.player = flowplayer('#player', {
      token: this.token,
      src: this.src,
      poster: this.poster,
      ratio: this.ratio,
      autoplay: this.autoplay,
      muted: this.muted,
    });
  },

  destroyed() {
    this.player.destroy();
  },

  created() {
    // Add the flowplayer stylesheet
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = FLOWPLAYER_STYLE_URL;
    document.head.appendChild(link);
  },
};
</script>
